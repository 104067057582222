
import { defineComponent, PropType, markRaw } from "vue-demi";
import { Avatar, Document, Service, SwitchButton, Tools, Money, PieChart, QuestionFilled, UserFilled } from '@element-plus/icons';
import roleFilter from "@/filters/role.filter";
import { ACCESSES_ENUM, ROLES_ENUM } from '@/utils/enums';
import { User } from "@/interfaces/dto/user";

export default defineComponent({
  name: "Profile",
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      navigation: [
        { link: '/profile', name: 'Профиль', icon: markRaw(Avatar), access: true },
        { link: '/faq', name: 'FAQ', icon: markRaw(QuestionFilled), access: false },
        { link: '/support', name: 'Список СП', icon: markRaw(Service), access: false },
        { link: '/experts', name: 'Список экспертов', icon: markRaw(UserFilled), access: false },
        { link: '/questionnaires', name: 'Анкеты', icon: markRaw(Document), access: false },
        { link: '/questionnaires/archive', name: 'Архив анкет', icon: markRaw(Document), access: false },
        { link: '/assets', name: 'Смешанные активы', icon: markRaw(PieChart), access: false },
        { link: '/currencies', name: 'Валюты', icon: markRaw(Money), access: false },
        { link: '/instruments', name: 'Инструменты', icon: markRaw(Tools), access: false },
      ],
      showMenu: false,
      courseElement: null
    }
  },
  created() {
    this.navigation[1].access = this.faqAccess
    if (this.user.role === ROLES_ENUM.STUDENT) this.getCourseElement();
  },
  computed: {
    staff() {
      return this.user && (this.user.accesses.includes(ACCESSES_ENUM.EXPERT) || this.user.role === ROLES_ENUM.OWNER);
    },
    faqAccess() {
      return this.user && (this.user.role !== ROLES_ENUM.STUDENT);
    }
  },
  methods: {
    roleFilter,
    async getCourseElement() {
      const result = await this.API.student.getCourseElement(this.user._id);
      this.courseElement = result.data;
    },
  },
  components: {
    Avatar, Document, Service, SwitchButton, Tools, Money, PieChart, QuestionFilled, UserFilled
  }
})
