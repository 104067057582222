
import { defineComponent, PropType } from "vue-demi";
import { NAVIGATION } from "@/store/commonDatas";
import { dynamicsObject } from "@/interfaces";
import { User } from "@/interfaces/dto/user";

export default defineComponent({
  name: "Nav",
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true,
    },
  },
  data() {
    return {
      nav: NAVIGATION,
      current_nav: [] as Array<dynamicsObject>,
    };
  },
  created() {
    this.current_nav = this.nav[this.user.role];
  },
});
