import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42bbbae6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.current_nav, (element) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: element.link,
          key: element.link,
          custom: ""
        }, {
          default: _withCtx(({ navigate, isExactActive }) => [
            _createElementVNode("li", {
              class: _normalizeClass({ active: isExactActive }),
              onClick: navigate
            }, _toDisplayString(element.name), 11, _hoisted_3)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}